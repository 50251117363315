<template>
  <div
    class="toolbar"
    ref="toolbar"
  >
    <slot></slot>
  </div>
</template>

<script>
//= mixins
import { ready } from '@/assets/js/mixins/base/Ready'
import { getStyles } from '@/assets/js/mixins/base/GetStyles'
import { getApp } from '@/assets/js/mixins/common/GetApp'

export default {
  name: 'toolbar',

  inject: {
    boxwrapConfig: {}
  },

  mixins: [ ready, getStyles, getApp ],

  watch: {
    'getApp.drawer': function () {
      this.getToolbarHeight()
    }
  },

  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.getToolbarHeight)
      this.getToolbarHeight()
    })
  },

  methods: {
    getToolbarHeight () {
      this.$loaded(() => {
        if (this.$refs.toolbar) {
          let margin = this.getComputedStyles('marginBottom', this.$refs.toolbar)

          this.boxwrapConfig.toolbar_h = this.$refs.toolbar.offsetHeight + margin
        }
      }, 1000)
    }
  }
}
</script>
